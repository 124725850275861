import React from 'react'

import Layout from '../../components/english_layout'
import SEO from '../../components/seo'
import Banner from '../../components/banner'
import Foot from '../../components/footer'

import '../../styles/about.sass'

import 'react-multi-carousel/lib/styles.css'

const HoodieHackingPage = () => {
    return (<Layout languageSwitchLink="/hoodiehacking">
        <SEO title="Hoodie Hacking"/>
        <Banner class_suffix="deep"/>
        <center>
            <div className="about-container">
                <h1 style={{ fontSize: '600%' }}>Hoodie Hacking !</h1>
                <p style={{ width: '60%', marginLeft: 'auto', marginRight: 'auto' }}>
                    Welcome to the Hoodie Hacking Track of PolyCyber 😎
                    <br />
                    <br /> If you're not a real hacker yet, you can go back to the home page here : <a href="/en">Home</a>
                    <br />Otherwise, for real hackers, find out what the difference is between this photo and your hoodie ...
                </p>
                <img src='/hoodie_hacking_stuff/hoodie_hacking.png' style={{ width:'80%'}}/>
            </div>
        </center>
        <Foot></Foot>
    </Layout>)
}
export default HoodieHackingPage